import router from '@/router'

export const directToFacilityApplication = (applicationId: string) => {
  router.push({
    name: 'facility-apply',
    params: {
      facilityApplicationId: applicationId
    }
  })
}
