import { acceptInvitation } from '@/graphql'
import { apolloClient } from '@/graphql/client'
import { MY_INVITATION_QUERY } from '@/graphql/queries'
import store, { FETCH_USER_STATE, SET_ORGANISATION_ID, USER_MOD } from '@/store'
import { LoadingProgrammatic as Loading, ToastProgrammatic as Toast } from 'buefy'
import { v4 as uuid } from 'uuid'
import { RouteConfig } from 'vue-router'

// Accounts
const Account = () => import('@/views/Account.vue')
const AccountLoginCard = () => import('@/views/AccountLoginCard.vue')
const AccountRegisterCard = () => import('@/views/AccountRegisterCard.vue')
const AccountResetCard = () => import('~/components/AccountResetCard.vue')
const AccountInviteCard = () => import('@/views/Placeholder.vue')
const AccountVerificationCard = () => import('@/views/AccountVerificationCard.vue')
const AccountConfirmVerificationCard = () => import('@/views/AccountConfirmVerificationCard.vue')
const PageNotFound = () => import('~/components/PageNotFound.vue')

// Documents
const Documents = () => import('@/views/Documents.vue')
const DocumentAdd = () => import('@/views/DocumentAdd.vue')
const DocumentHandover = () => import('@/views/DocumentHandover.vue')
const DocumentAddProjected = () => import('@/views/DocumentAddProjected.vue')
const DocumentView = () => import('@/views/DocumentView.vue')

// Drafts
const DraftCreate = () => import('@/views/DraftCreate.vue')
const DraftView = () => import('@/views/DraftView.vue')
const DraftSubmissionView = () => import('@/views/DraftSubmissionView.vue')
const DraftCreateSuretyBond = () => import('@/views/DraftCreateSuretyBond.vue')
const BankGuaranteeSwitch = () => import('@/views/BankGuaranteeSwitch.vue')

// Reports
const Reports = () => import('@/views/Reports.vue')

// Shared With Me
const SharedWithMe = () => import('@/views/SharedWithMe.vue')

// Members
const Members = () => import('@/views/Members.vue')

// Profile
const Profile = () => import('@/views/Profile.vue')

// Settings
const Settings = () => import('@/views/Settings.vue')
const SettingOrganisation = () => import('@/views/SettingOrganisation.vue')
const SettingAlerts = () => import('@/views/SettingAlerts.vue')
const OrganisationMetadataSettings = () => import('@/views/OrganisationMetadataSettings.vue')
const SettingBilling = () => import('@/views/SettingBilling.vue')

// Facilities
const DocumentFacilities = () => import('@/views/DocumentFacilities.vue')
const DocumentFacilityCreate = () => import('@/views/DocumentFacilityCreate.vue')
const DocumentFacilityApply = () => import('@/views/DocumentFacilityApply.vue')
const DocumentFacilityApplyIntro = () => import('@/views/DocumentFacilityApplyIntro.vue')
const DocumentFacilityEdit = () => import('@/views/DocumentFacilityEdit.vue')
const DocumentFacilityView = () => import('@/views/DocumentFacilityView.vue')

// Origination
const OriginationCreate = () => import('@/views/OriginationCreate.vue')

// Content
const ContentReader = () => import('@/views/ContentReader.vue')

//Email progress tracking
const EmailProgressTracking = () => import('@/views/EmailProgressTracking.vue')

//Home Page
const HomePage = () => import('@/views/Home.vue')

//Finance Page
const ApplyFinancePage = () => import('@/views/ApplyFinance.vue')
const FinanceForm = () => import('@/views/FinanceForm.vue')

//Referral page
const Referral = () => import('@/views/Referral.vue')

const processedInviteCodes = new Set<string>()

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'default',
    redirect: () => ({ name: 'home' })
  },
  {
    path: '/home',
    name: 'home',
    component: HomePage
  },
  {
    path: '/finance',
    name: 'finance',
    component: ApplyFinancePage,
    props: true,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/finance/apply',
    name: 'finance-apply',
    component: FinanceForm,
    props: true,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/referral',
    name: 'referral',
    component: Referral,
    props: true,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/progress/:organisationId/:draftId',
    name: 'progress',
    component: EmailProgressTracking
  },
  {
    path: '/handover',
    name: 'handover',
    component: Account,
    children: [
      {
        path: ':documentId/:handoverCode',
        name: 'document-handover',
        component: DocumentHandover,
        meta: { public: true },
        props: true
      }
    ]
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    redirect: { name: 'account-login' },
    children: [
      {
        path: 'login',
        name: 'account-login',
        component: AccountLoginCard,
        meta: { anonymous: true }
      },
      {
        path: 'register',
        name: 'account-register',
        component: AccountRegisterCard,
        meta: { anonymous: true }
      },
      {
        path: 'verification-pending',
        name: 'verification-pending',
        component: AccountVerificationCard,
        props: true,
        meta: { anonymous: true }
      },
      {
        path: 'reset',
        name: 'account-reset',
        component: AccountResetCard,
        meta: { anonymous: true }
      },
      {
        path: 'invite',
        name: 'account-invite',
        component: AccountInviteCard,
        props: true
      },
      {
        path: '/account/verify',
        name: 'account-verify',
        component: AccountConfirmVerificationCard,
        meta: { anonymous: true },
        beforeEnter: (to, from, next) => {
          if (!to.query['username'] || !to.query['code']) {
            return next({ name: 'default' })
          }
          return next()
        }
      }
    ]
  },
  {
    path: '/documents',
    name: 'documents',
    component: Documents,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/documents/add',
    name: 'document-add',
    component: DocumentAdd,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/documents/add/projected',
    name: 'document-add-projected',
    component: DocumentAddProjected,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/documents/:organisationId/:documentId/view',
    name: 'org-document-view',
    component: DocumentView,
    props: true
  },
  {
    path: '/documents/:documentId/view',
    name: 'document-view',
    component: DocumentView,
    props: true,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/bond-surety-drafts/create',
    name: 'draft-bond-surety-create',
    meta: { isSuretyBondsEnabled: true },
    component: DraftCreateSuretyBond,
    props: true
  },
  {
    path: '/bank-guarantee-switch-drafts/create',
    name: 'draft-bank-guarantee-switch-create',
    meta: { isBGSwitchRoute: true, isDefaultRoute: true },
    component: BankGuaranteeSwitch,
    props: true
  },
  {
    path: '/drafts/create',
    name: 'draft-create',
    meta: { isDefaultRoute: true },
    component: DraftCreate
  },
  {
    path: '/drafts/:organisationId/create',
    name: 'org-draft-create',
    component: DraftCreate,
    props: true
  },
  {
    path: '/drafts/:organisationId/:draftId/view',
    name: 'draft-view',
    component: DraftView,
    props: true
  },
  {
    path: '/drafts/:organisationId/:draftId/submit',
    name: 'draft-submission',
    meta: { isDefaultRoute: true },
    component: DraftSubmissionView,
    props: true
  },
  {
    path: `/accept-invitation/organisation/:code`,
    name: 'org-invitation-accept',
    beforeEnter: async (to, from, next) => {
      let organisation = ''
      let invitation
      const code = to.params.code.replace(/\W/g, '')
      if (processedInviteCodes.has(code)) {
        // remove next-path to avoid continuous bouncing between paths
        localStorage.removeItem('next-path')
        // this is to avoid the same invite code trying to be processed twice
        // which causes the catch statement below to be hit
        return next({ name: 'default' })
      }

      processedInviteCodes.add(code)
      const loadingComponent = Loading.open({})
      try {
        const data = await apolloClient().query({ query: MY_INVITATION_QUERY, variables: { code } })
        invitation = (data as any).data.me.invitations.edges[0].node
        organisation = invitation.invitedTo
        const organisationId = invitation.organisationId
        await acceptInvitation({ clientMutationId: uuid(), code })
        Toast.open({ message: `Successfully accepted invitation from ${organisation}` })
        await store.commit(`${USER_MOD}/${SET_ORGANISATION_ID}`, organisationId)
        await store.dispatch(`${USER_MOD}/${FETCH_USER_STATE}`)
      } catch (e) {
        Toast.open({
          type: 'is-danger',
          message: !invitation
            ? 'Your invitation is invalid'
            : `Failed to ${organisation ? 'accept' : 'find'} invitation`
        })
      } finally {
        loadingComponent.close()
      }
      return next({ name: 'default' })
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/shared-with-me',
    name: 'shared-with-me',
    component: SharedWithMe,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/members',
    name: 'members',
    component: Members,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    redirect: { name: 'setting-organisation' },
    children: [
      {
        path: 'organisation',
        name: 'setting-organisation',
        component: SettingOrganisation,
        meta: { isDefaultRoute: true }
      },
      {
        path: 'alerts',
        name: 'setting-alerts',
        component: SettingAlerts,
        meta: { isDefaultRoute: true }
      },
      {
        path: 'metadata',
        name: 'setting-metadata',
        component: OrganisationMetadataSettings,
        meta: { isDefaultRoute: true }
      },
      {
        path: 'billing',
        name: 'setting-billing',
        component: SettingBilling,
        meta: { isDefaultRoute: true }
      }
    ]
  },

  {
    path: '/terms',
    name: 'terms',
    component: ContentReader,
    props: {
      name: 'terms'
    },
    meta: { public: true }
  },
  {
    path: '/financial-service-guide',
    name: 'financial-service-guide',
    component: ContentReader,
    props: {
      name: 'financial-service-guide'
    },
    meta: { public: true }
  },
  {
    path: '/referral-program-terms-and-conditions',
    name: 'referral-program-terms-and-conditions',
    component: ContentReader,
    props: {
      name: 'referral-program-terms-and-conditions'
    },
    meta: { public: true }
  },
  {
    path: '/bond-privacy',
    name: 'bond-privacy',
    component: ContentReader,
    props: {
      name: 'bond-privacy'
    },
    meta: { public: true }
  },
  {
    path: '/submissionTerms',
    name: 'submissionTerms',
    component: ContentReader,
    props: {
      name: 'terms'
    }
  },
  {
    path: '/facilities',
    name: 'facilities',
    component: DocumentFacilities
  },
  {
    path: '/facilities/create',
    name: 'facility-create',
    component: DocumentFacilityCreate,
    meta: { isDefaultRoute: true }
  },
  {
    path: '/facilities/apply-intro/:facilityType',
    name: 'facility-apply-intro',
    component: DocumentFacilityApplyIntro,
    meta: { isDefaultRoute: true },
    props: true
  },
  {
    path: '/facilities/apply/:facilityApplicationId',
    name: 'facility-apply',
    component: DocumentFacilityApply,
    props: true
  },
  {
    path: '/facilities/:facilityId/edit',
    name: 'facility-edit',
    component: DocumentFacilityEdit,
    props: true
  },
  {
    path: '/facilities/:facilityId/view',
    name: 'facility-view',
    component: DocumentFacilityView,
    props: true
  },
  {
    path: '/originations/:originationId',
    name: 'origination-create',
    component: OriginationCreate,
    props: true,
    meta: { isDefaultRoute: false }
  },
  { path: '*', name: 'page-not-found', component: PageNotFound }
]

export { routes }
